$primary: #DEB219;
$secondary: #242424;
@import "bootstrap/scss/bootstrap";


.full-page {
min-height: 100vh !important;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.spiner {
    z-index: 1;
    position: fixed;
    top: 0;
    background-color: rgba($color: $secondary, $alpha: 0.5);
    height: 100vh;
    width: 100vw;
    div{
        width: 5rem;
        height: 5rem;
    }
}
